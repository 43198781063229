import { useLocation, useNavigate } from 'react-router-dom';
import { Navigation } from 'react-minimal-side-navigation';
import { useEffect, useMemo, useState } from 'react';
import { getDictionaryMenu } from 'api/rolesAndPermissions';
import { logout } from 'store/slice/authSlice';
import { permissionMenu } from 'utils/menu';
import { topMenuItems } from 'constants/menuItems';
import { ChatIcon } from 'pages/Chat/components/ChatIcon/ChatIcon';
import LogOutSvg from 'components/ui/Icons/LogOutSvg';
import { useAppDispatch, useAppSelector } from 'store';
import { selectChatUnreadThreads } from 'store/slice/chat/chatSlice';
import { checkChatFeature } from 'pages/Chat/helpers/checkChatFeature';
import { compact } from 'lodash';

export const MenuItems = ({ onClose, isMobile = false }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const hasChat = checkChatFeature();
  const chatUnreadCount = useAppSelector(selectChatUnreadThreads);

  const [menuItems, setMenuItems] = useState([]);

  const staticMenuItems = useMemo(
    () =>
      compact([
        hasChat && {
          title: 'Chat',
          itemId: '/chat',
          elemBefore: () => <ChatIcon messagesCount={chatUnreadCount} />,
        },
        {
          title: 'Logout',
          itemId: '/logout',
          elemBefore: () => <LogOutSvg />,
        },
      ]),
    [hasChat, chatUnreadCount],
  );

  const topLevelPath = useMemo(
    () =>
      menuItems.filter((item) => {
        if (item['subNav'] && item['subNav'].length) {
          return item;
        }
      }),
    [menuItems],
  );

  useEffect(() => {
    getDictionaryMenu()
      .then((res) => {
        setMenuItems(permissionMenu(res.data.data, topMenuItems));
      })
      .catch(console.log);
  }, [logout]);

  useEffect(() => {
    const hasStaticButtons = menuItems.some(
      (item) => item.itemId === '/chat' || item.itemId === '/logout',
    );

    if (menuItems.length && !hasStaticButtons) {
      setMenuItems((prev) => prev.concat(staticMenuItems));
    }
  }, [menuItems, staticMenuItems]);

  useEffect(() => {
    if (hasChat) {
      setMenuItems(menuItems.map(item => {
        if (item.itemId === '/chat') {
          return {
            title: 'Chat',
            itemId: '/chat',
            elemBefore: () => <ChatIcon messagesCount={chatUnreadCount} />,
          }
        }
        return item
      }));
    }
  }, [chatUnreadCount]);

  return (
    <>
      <Navigation
        items={menuItems}
        activeItemId={location.pathname}
        onSelect={({ itemId }) => {
          const exist = topLevelPath.find((topItem) => topItem.itemId.includes(itemId));

          if (!exist) {
            if (itemId === '/logout') {
              dispatch(logout());
              navigate('/');
            } else {
              navigate(itemId);
              isMobile && onClose(false);
            }
          }
        }}
      />
    </>
  );
};
